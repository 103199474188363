import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { Link, Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { t, Trans } from '@lingui/macro';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import MailIcon from '@material-ui/icons/Mail';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import Grid from '@material-ui/core/Grid';
import poweredByIntertekLogo from '../../../assets/images/poweredByIntertek-logo.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: `${theme.palette.background.default}`,
    borderTop: `solid 1px ${theme.palette.background.paper}`,
    overflowX: 'hidden',
    bottom: '0px',
    left: 'auto',
    width: '100%',
    right: '0px',
    marginBottom: '0px',
  },
  footerText: {
    fontSize: '14px',
    lineHeight: 1.5,
  },
  white: {
    color: theme.palette.white,
  },
  footerTitle: {
    fontSize: '12px',
    color: theme.palette.disabledIcon,
  },
  footer: {
    paddingTop: '10px',
  },
  logo: {
    marginLeft: '20px',
  },
  socialMedia: {
    padding: '10px',
    fontSize: 12,
  },
  policy: {
    marginTop: '2px',
    fontSize: 12,
  },
  helpIcon: {
    borderRight: '1px solid white',
    paddingRight: '5px',
    color: theme.palette.white,
  },
  mailCenter: {
    fontSize: 12,
  },
  iconSize: {
    marginTop: '3px',
  },
}));

export default function AppFooter() {
  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  return isAuthenticated && (
    <div className={classes.root}>
      <footer>
        <Grid
          container
          spacing={0}
          justify="space-between"
          classes={{
            root: classes.footer,
          }}
        >
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="column"
              classes={{
                root: classes.logo,
              }}
            >
              <Grid item>
                <img
                  alt="Logo"
                  src={poweredByIntertekLogo}
                  height="30"
                  width="115"
                />
              </Grid>
              <Grid item>
                <Typography className={classes.footerTitle}>
                  &copy;
                  {' '}
                  {t`Alchemy Zosi ${new Date().getFullYear().toString()}`}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              direction="column"
              justify="center"
            >
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  justify="center"
                  classes={{
                    root: classes.policy,
                  }}
                >
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.alchemysystems.com/"
                      target="_blank"
                      className={classes.helpIcon}
                    >
                      <Trans>Terms and Conditions</Trans>
                    </Link>
                  </Grid>
                  {isFeatureEnabled && (
                    <Grid item>
                      <Link
                        component="a"
                        href="https://www.alchemysystems.com/"
                        target="_blank"
                        className={classes.helpIcon}
                      >
                        <Trans>FAQ</Trans>
                      </Link>
                    </Grid>
                  )}
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.alchemysystems.com/"
                      target="_blank"
                      className={classes.helpIcon}
                    >
                      <Trans>Privacy Policy</Trans>
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="https://www.alchemysystems.com/"
                      target="_blank"
                      className={classes.white}
                    >
                      <Trans>Support Center</Trans>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid
                  container
                  spacing={1}
                  classes={{
                    root: classes.mailCenter,
                  }}
                >
                  <Grid item>
                    <Link
                      component="a"
                      href="mailto:supportexpress@alchemysystems.com"
                      target="_blank"
                      className={classes.white}
                    >
                      <MailIcon
                        fontSize="inherit"
                        classes={{
                          root: classes.iconSize,
                        }}
                      />
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link
                      component="a"
                      href="mailto: supportexpress@alchemysystems.com"
                      target="_blank"
                      className={classes.white}
                    >
                      <Trans>Alchemy Support, US and Canada</Trans>
                    </Link>
                  </Grid>
                  <Grid item>
                    <WatchLaterIcon
                      fontSize="inherit"
                      classes={{
                        root: classes.iconSize,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Trans> Hours of Operation: Monday – Friday, 5 AM – 7 PM CT</Trans>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid
              container
              spacing={0}
              classes={{
                root: classes.socialMedia,
              }}
            >
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://www.facebook.com/IntertekAlchemy/"
                    target="_blank"
                    className={classes.white}
                  >
                    <FacebookIcon />
                  </Link>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://twitter.com/intertekalchemy"
                    target="_blank"
                    className={classes.white}
                  >
                    <TwitterIcon />
                  </Link>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://www.linkedin.com/company/alchemy-systems"
                    target="_blank"
                    className={classes.white}
                  > <LinkedInIcon />
                  </Link>
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                >
                  <Link
                    component="a"
                    href="https://www.youtube.com/user/AlchemySystemsLP"
                    target="_blank"
                    className={classes.white}
                  >   <YouTubeIcon />
                  </Link>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </div>
  );
}
