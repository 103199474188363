import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { makeStyles, useTheme } from '@material-ui/styles';
import { useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import Sidebar from './components/Sidebar/Sidebar';
import Topbar from '../commonComponents/Topbar/Topbar';
import Footer from '../commonComponents/Footer/AppFooter';
import useAppContext from '../../hooks/useAppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    paddingTop: 56,
    [theme.breakpoints.up('md')]: {
      paddingTop: 64,
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minHeight: '86vh',
    padding: '20px',
    paddingBottom: '0px',
  },
  devText: {
    margin: '10px',
    position: 'absolute',
    bottom: 0,
    opacity: '0.6',
  },
}));

const Main = (props) => {
  const { children, disableMenu, hideTopBarClient } = props;

  const classes = useStyles();
  const theme = useTheme();
  const { currentEnv } = useAppContext();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <>
      <div
        className={classes.root}
      >
        <Topbar hideTopBarClient={hideTopBarClient} />
        <Sidebar
          open
          disableMenu={disableMenu}
          variant={isDesktop ? 'permanent' : 'temporary'}
        />
        <main className={classes.content}>
          {children}
          {currentEnv === 'DEV' && <footer className={classes.devText}><Trans>DEV ENV</Trans></footer>}
        </main>
      </div>
      <Footer />
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
  disableMenu: PropTypes.bool.isRequired,
  hideTopBarClient: PropTypes.bool.isRequired,
};

export default withRouter(Main);
