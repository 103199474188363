import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';
import styles from '../../styles';

export default function Loading({ isLoading }) {
  const classes = styles();
  return (
    <Backdrop open={isLoading} className={classes.backdrop} data-testid="LoadingIndicator">
      <CircularProgress color="inherit" />
    </Backdrop>
  );
}

Loading.propTypes = {
  isLoading: PropTypes.bool,
};

Loading.defaultProps = {
  isLoading: false,
};
