import React from 'react';
import PropTypes from 'prop-types';
import { QueryCache, ReactQueryCacheProvider, setConsole } from 'react-query';

const config = {
  defaultConfig: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      suspense: true,
    },
    mutations: {
      useErrorBoundary: false,
    },
  },
};

setConsole({
  error: () => {},
});

const queryCache = new QueryCache(config);

const AppReactQueryProvider = ({ children }) => (
  <ReactQueryCacheProvider queryCache={queryCache}>
    {children}
  </ReactQueryCacheProvider>
);

AppReactQueryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppReactQueryProvider;
