import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import loadPendoScript from '../helper/loadPendoScript';

export default function usePendo() {
  const pendoKey = process.env.REACT_APP_PENDO_API_KEY;
  const {
    user: { sub: userId = '' } = {},
  } = useAuth0();

  useEffect(() => {
    if (pendoKey) {
      loadPendoScript(pendoKey, userId);
    }
  }, [pendoKey, userId]);
}
