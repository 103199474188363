import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Trans, t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FormHelperText from '@material-ui/core/FormHelperText';

import getAllClients from '../../api/admin';
import { CLIENT_ID } from '../../constants/appConstants';

const useStyles = makeStyles((theme) => ({
  itemHeader: {
    marginBottom: '20px',
  },
  itemContainer: {
    backgroundColor: theme.palette.background.container,
    minHeight: 'calc(100vh - 150px)',
    padding: '20px',
  },
  buttonItem: {
    paddingTop: 8,
    paddingRight: 8,
  },
}));

const ClientSelection = () => {
  const classes = useStyles();
  const history = useHistory();

  const matches = useMediaQuery((theme) => theme.breakpoints.down('xs'));
  const { data: { data: clientList = [] } = [] } = useQuery('GetAllClients', getAllClients);

  const {
    handleSubmit, errors, formState, control,
  } = useForm({ mode: 'onBlur' });

  const { isValid } = formState;
  const submitData = async (data) => {
    localStorage.setItem(CLIENT_ID, JSON.stringify(data.clientSelection));
    history.go(0);
  };

  return clientList && (
    <Grid container direction="column">
      <Grid item classes={{ root: classes.itemHeader }} xs={12}>
        <Grid
          container
          alignItems="center"
        >
          <Grid item sm="auto" xs={12}>
            <Typography variant="subtitle2">
              <Trans>Client Selection</Trans>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} item classes={{ root: classes.itemContainer }}>
        <form noValidate onSubmit={handleSubmit(submitData)}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <FormControl
                variant="filled"
                fullWidth
                error={!!errors.clientSelection}
              >
                <InputLabel id="input-language" htmlFor="preferredLanguage">
                  <Trans>Select Client</Trans>
                </InputLabel>
                <Controller
                  as={(
                    <Select
                      id="clientSelection"
                      label={<Trans>Select Client</Trans>}
                      disableUnderline
                    >
                      {clientList.map((option) => (
                        <MenuItem key={option.id} value={option}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                  name="clientSelection"
                  control={control}
                  defaultValue={clientList[0]}
                  rules={{ required: t`Please select client` }}
                />
                {errors.clientSelection
                && (<FormHelperText>{errors?.clientSelection?.message}</FormHelperText>)}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container classes={{ root: classes.buttonItem }} md={6} xs={12} justify="flex-end">
            <Button variant="contained" disabled={!isValid} color="primary" onClick={handleSubmit(submitData)} fullWidth={matches}><Trans>GO</Trans></Button>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ClientSelection;
