import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: theme.spacing(3.25),
  },
  menuItem: {
    fontSize: theme.spacing(1.75),
    fontWeight: 'normal',
    backgroundColor: '#353535',
    '&:hover': {
      backgroundColor: theme.palette.common.black,
    },
  },
}));

export default useStyles;
