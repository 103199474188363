import axios from 'axios';
import { CLIENT_ID } from '../constants/appConstants';

const { REACT_APP_AUTH0_AUDIENCE, REACT_APP_API_GATEWAY_URL } = process.env;
const apiInterceptor = async (getAccessTokenSilently, isAuthenticated, logout) => {
  axios.interceptors.request.use(async (req) => {
    if (req.url.includes(REACT_APP_API_GATEWAY_URL)
        && 'scope' in req.headers && isAuthenticated) {
      try {
        const token = await getAccessTokenSilently({
          audience: REACT_APP_AUTH0_AUDIENCE,
          scope: req?.headers?.scope,
        });
        req.headers.Authorization = `Bearer ${token}`;
      } catch (error) {
        logout({ returnTo: window.location.origin });
      }
    }
    req.params = { ...req.params, clientId: JSON.parse(localStorage.getItem(CLIENT_ID))?.id };
    delete req?.headers?.scope;
    return Promise.resolve(req);
  });
};

export default apiInterceptor;
