import React from 'react';
import PropTypes from 'prop-types';
import { ReactQueryErrorResetBoundary } from 'react-query';
import ErrorPage from './ErrorPage';

/** NOTE: This component is develop using class as we dont have getDerivedStateFromError
    and componentDidCatch life-cycle methods in functional component.
    @see <a href="https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries">Error Boundaries</a>.
*/
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error, errorInfo) {
    // TODO: log the error to the server
    // eslint-disable-next-line no-console
    console.log('Please check error at: ', errorInfo.componentStack);
  }

  tryAgain = () => {
    const { onReset } = this.props;
    this.setState({ hasError: false });
    onReset();
  }

  renderChildren = (children) => children;

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    return hasError ? (
      <ErrorPage handleOnRetryClick={this.tryAgain} />
    ) : this.renderChildren(children);
  }
}

const ErrorBoundaryComponent = ({ children }) => (
  <ReactQueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary
        onReset={reset}
      >
        {children}
      </ErrorBoundary>
    )}
  </ReactQueryErrorResetBoundary>
);

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  onReset: PropTypes.func.isRequired,
};

ErrorBoundaryComponent.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundaryComponent;
