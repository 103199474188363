import React, { Suspense, lazy } from 'react';
import {
  Switch, Redirect, useLocation, Route,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './components/Loading';
import ErrorBoundary from './components/ErrorBoundary';

// Layouts
import RouteWithLayout, { PrivateLayoutRoute as PrivateComponent, AuthenticateForAdmin } from './components/RouteWithLayout/RouteWithLayout';
import Layouts from './layouts';
import { CLIENT_ID } from './constants/appConstants';

// Containers
const ClientDetails = lazy(() => import('./containers/ClientDetails'));
const Downloads = lazy(() => import('./containers/Downloads'));
const ChangePasswordDialog = lazy(() => import('./containers/ChangePasswordDialog'));
const UserListing = lazy(() => import('./containers/UserListing'));
const CreateUser = lazy(() => import('./containers/CreateUser'));
const NotFound = lazy(() => import('./containers/NotFound'));
const LibraryDetails = lazy(() => import('./containers/LibraryDetails'));
const LoginPage = lazy(() => import('./components/LoginPage'));
const UserDetail = lazy(() => import('./containers/UserDetail'));
const Profile = lazy(() => import('./containers/Profile'));
const supportCenter = lazy(() => import('./components/SupportCenter'));
const MyLearnings = lazy(() => import('./containers/MyLearnings'));
const MyOrders = lazy(() => import('./containers/MyOrders'));
const OrderDetail = lazy(() => import('./containers/OrderDetail'));
const MyCertificates = lazy(() => import('./containers/MyCertificates'));
const WelcomePage = lazy(() => import('./containers/WelcomePage'));

const { Main, Empty } = Layouts;

const Routes = (appProps) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get('clientid');
  const name = query.get('clientname');
  if (id && name) localStorage.setItem(CLIENT_ID, JSON.stringify({ id, name }));
  const isFromAdminQuery = !!query.get('isauthenticated');
  if (isFromAdminQuery) localStorage.setItem('isFromAdmin', isFromAdminQuery);
  const isModal = location.state && location.state.isModal;
  const isFromAdmin = JSON.parse(localStorage.getItem('isFromAdmin'));
  let PrivateLayoutRoute = PrivateComponent;
  const { isAuthenticated } = useAuth0();
  if (isAuthenticated) {
    localStorage.removeItem('isFromAdmin');
    PrivateLayoutRoute = PrivateComponent;
  }

  if (isFromAdmin) {
    PrivateLayoutRoute = AuthenticateForAdmin;
  }
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading isLoading />}>
        <Switch location={isModal || location}>
          <Redirect exact from="/:url*(/+)" to={location?.pathname?.slice(0, -1)} />
          <Redirect exact from="/" to="/downloads/libraries" />
          <Redirect exact from="/downloads" to="/downloads/libraries" />
          <Redirect exact from="/index.html" to="/login" />
          <RouteWithLayout
            appProps={appProps}
            component={LoginPage}
            exact
            layout={Empty}
            path="/login"
          />
          <RouteWithLayout
            appProps={appProps}
            component={WelcomePage}
            exact
            layout={Empty}
            path="/welcome"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={Downloads}
            layout={Main}
            exact
            path="/downloads/:tabs"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={ClientDetails}
            layout={Main}
            path="/clientDetails"
          />
          <RouteWithLayout
            appProps={appProps}
            component={NotFound}
            exact
            layout={Empty}
            path="/notFound"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={UserListing}
            layout={Main}
            exact
            path="/users"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={CreateUser}
            layout={Main}
            exact
            path="/users/create"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={Profile}
            layout={Main}
            exact
            path="/users/profile"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={UserDetail}
            layout={Main}
            exact
            path="/users/details/:id"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={LibraryDetails}
            layout={Main}
            exact
            path="/downloads/libraries/:id"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={supportCenter}
            layout={Main}
            exact
            path="/support"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={MyOrders}
            layout={Main}
            exact
            path="/myOrders"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={OrderDetail}
            layout={Main}
            exact
            path="/myOrders/:id"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={MyLearnings}
            layout={Main}
            exact
            path="/myLearning"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={MyCertificates}
            layout={Main}
            exact
            path="/myCertificates"
          />
          <PrivateLayoutRoute
            appProps={appProps}
            component={OrderDetail}
            layout={Main}
            exact
            path="/myLearning/:id"
          />
          <Redirect to="/notFound" />
        </Switch>
        {isModal && (
          <Route path="/changePassword">
            <ChangePasswordDialog />
          </Route>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default Routes;
