import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFeatureToggle, ToggleFeature } from '@flopflip/react-broadcast';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Trans, t } from '@lingui/macro';
import { useAuth0 } from '@auth0/auth0-react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import SearchIcon from '@material-ui/icons/Search';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Divider from '@material-ui/core/Divider';
import {
  Grid,
} from '@material-ui/core';
import useAppContext from '../../../hooks/useAppContext';
import useLogout from '../../../hooks/useLogout';
import expressLogo from '../../../assets/images/IntertekExpressLogo.svg';
import darkThemeLogo from '../../../assets/images/IntertekLogo.svg';
import lightThemeLogo from '../../../assets/images/alchemy-logo.png';
import Can from '../../../components/Can';
import SelectClients from './SelectClient';
import {
  ADMIN_ROLE, CLIENT_ID, ACCOUNT_OWNER_ROLE, CLIENT_ROLE,
} from '../../../constants/appConstants';
import SelectLanguage from './SelectLanguage';
import ClientMenu from '../../../components/ClientMenu';

const { REACT_APP_ADMIN_URL, REACT_APP_WP_URL } = process.env;
const useStyles = makeStyles((theme) => ({
  flexGrow: {
    flexGrow: 1,
  },
  small: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  devText: {
    margin: theme.spacing(1.25),
  },
  clientButton: {
    marginRight: theme.spacing(1.25),
  },
  divider: {
    marginTop: theme.spacing(1.75),
    marginBottom: theme.spacing(1.75),
  },
  buttonRoot: {
    marginLeft: theme.spacing(1.875),
  },
}));

const Topbar = ({ hideTopBarClient }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { currentEnv, theme } = useAppContext();
  const [menu, setMenu] = useState(null);
  const {
    isAuthenticated, loginWithRedirect, user,
  } = useAuth0();
  const { logout } = useLogout();
  const loginButton = isAuthenticated ? {
    title: t`Logout`,
    onClick: () => logout({ returnTo: window.location.origin }),
  } : {
    title: t`Login`,
    onClick: () => loginWithRedirect(),
  };

  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');

  return (
    <AppBar
      color="default"
      position="fixed"
    >
      <Toolbar>
        {isFeatureEnabled
          ? (
            <a href={REACT_APP_WP_URL}>
              <img
                alt="Logo"
                src={theme ? darkThemeLogo : lightThemeLogo}
                height="32"
                width="85"
              />
            </a>
          )
          : (
            <img
              alt="Logo"
              src={expressLogo}
              height="32"
              width="85"
            />
          )}
        {currentEnv === 'DEV' && (<Grid classes={{ root: classes.devText }}><Trans>DEV ENV</Trans></Grid>)}
        <ToggleFeature flag="enableLearnerMode">
          <ClientMenu
            title={t`My Portal`}
            clientRoleDetails={[
              { menuItem: t`Alchemy Express`, roles: [ADMIN_ROLE, ACCOUNT_OWNER_ROLE, CLIENT_ROLE], url: '/downloads' },
              { menuItem: t`My Learning`, roles: [], url: '/myLearning' },
              { menuItem: t`My Orders`, roles: [], url: '/myOrders' },
              { menuItem: t`My Certificates`, roles: [], url: '/myCertificates' },
            ]}
          >
            <ExpandMoreIcon />
          </ClientMenu>
        </ToggleFeature>
        <div className={classes.flexGrow} />
        <Can
          roleHasAccess={[CLIENT_ROLE, ACCOUNT_OWNER_ROLE]}
          user={user}
          yes={() => (
            <IconButton
              aria-label="search item"
              onClick={() => { }}
            >
              <SearchIcon />
            </IconButton>
          )}
        />
        <Divider orientation="vertical" flexItem classes={{ flexItem: classes.divider }} />
        <ClientMenu
          clientRoleDetails={[
            { menuItem: t`Terms & Conditions`, roles: [], href: 'https://www.alchemysystems.com/general-terms-conditions/' },
            { menuItem: t`FAQ`, roles: [CLIENT_ROLE, ACCOUNT_OWNER_ROLE], href: `${REACT_APP_WP_URL}resources/support` },
            { menuItem: t`Privacy Policy`, roles: [], href: 'https://www.alchemysystems.com/privacy/' },
            { menuItem: t`Support Center`, roles: [], href: `${REACT_APP_WP_URL}resources/support` }]}
        >
          <HelpIcon />
        </ClientMenu>
        <Can
          roleHasAccess={[CLIENT_ROLE, ACCOUNT_OWNER_ROLE]}
          user={user}
          yes={() => (
            <IconButton
              aria-label="search item"
              onClick={() => { }}
            >
              <ShoppingCartIcon />
            </IconButton>
          )}
        />
        <IconButton
          aria-label="account of current user"
          onClick={(event) => setMenu(event.currentTarget)}
        >
          <PersonIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          elevation={0}
          anchorEl={menu}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          getContentAnchorEl={null}
          open={Boolean(menu)}
          onClose={() => setMenu(null)}
        >
          <MenuItem
            onClick={() => {
              history.push('/users/profile');
              setMenu(null);
            }}
          >
            <Trans>Profile</Trans>
          </MenuItem>

          <MenuItem onClick={async () => {
            setMenu(null);
            history.push('/changePassword', { isModal: location });
          }}
          >
            <Trans>Change Password</Trans>
          </MenuItem>
          <Can
            roleHasAccess={[ADMIN_ROLE]}
            user={user}
            yes={() => (
              <MenuItem
                onClick={() => {
                  localStorage.removeItem(CLIENT_ID);
                }}
                component="a"
                href={REACT_APP_ADMIN_URL}
              >
                <Trans>Go to ZOSI</Trans>
              </MenuItem>
            )}
          />
          <MenuItem onClick={async () => {
            setMenu(null);
            loginButton.onClick();
          }}
          >
            {loginButton.title}
          </MenuItem>
        </Menu>
        {!hideTopBarClient && (<SelectClients />)}
        <SelectLanguage />
      </Toolbar>
    </AppBar>
  );
};
Topbar.defaultProps = {
  hideTopBarClient: true,
};

Topbar.propTypes = {
  hideTopBarClient: PropTypes.bool,
};

export default Topbar;
