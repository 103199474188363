/* eslint-disable no-console */
import { useAuth0 } from '@auth0/auth0-react';
import { useFeatureToggle } from '@flopflip/react-broadcast';
import { t } from '@lingui/macro';
import {
  Button, List, ListItem, ListItemIcon, Typography,
} from '@material-ui/core';
import { keys, isEmpty } from 'lodash';
import ClientDetails from '@material-ui/icons/BusinessOutlined';
import Downloads from '@material-ui/icons/GetApp';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ClassIcon from '@material-ui/icons/Class';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import ListAltIcon from '@material-ui/icons/ListAlt';
import UserLsitingIcon from '@material-ui/icons/PeopleAlt';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
import Can from '../../../../../../components/Can';
import {
  ACCOUNT_OWNER_ROLE, ADMIN_ROLE,
  CLIENT_ROLE,
} from '../../../../../../constants/appConstants';
import { ReactComponent as Zosi } from '../../../../../../assets/images/zosi.svg';
import { useStyles } from './styles';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));

const SidebarNav = ({
  disableMenu,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth0();
  const [open, setOpen] = React.useState(true);
  const isFeatureEnabled = useFeatureToggle('enableLearnerMode');

  let pages = {
    'Alchemy Express': {
      title: t`Alchemy Express`,
      icon: <Zosi />,
      roleHasAccess: [ADMIN_ROLE, ACCOUNT_OWNER_ROLE, CLIENT_ROLE],
      children: [
        {
          title: t`Downloads`,
          href: '/downloads',
          icon: <Downloads />,
          roleHasAccess: [ADMIN_ROLE, ACCOUNT_OWNER_ROLE, CLIENT_ROLE],
        },
        {
          title: t`Company Details`,
          href: '/clientDetails',
          icon: <ClientDetails />,
          roleHasAccess: [ADMIN_ROLE, ACCOUNT_OWNER_ROLE],
        },
        {
          title: t`Users`,
          href: '/users',
          icon: <UserLsitingIcon />,
          roleHasAccess: [ADMIN_ROLE, ACCOUNT_OWNER_ROLE],
        },
      ],
    },
  };

  const learning = {
    'My Learning': {
      title: t`My Learning`,
      href: '/myLearning',
      icon: <LibraryBooksIcon />,
      roleHasAccess: [],
      children: [],
    },
    'My Orders': {
      title: t`My Orders`,
      href: '/myOrders',
      icon: <ListAltIcon />,
      roleHasAccess: [],
      children: [],
    },
    'My Certificates': {
      title: t`My Certificates`,
      href: '/myCertificates',
      icon: <ClassIcon />,
      roleHasAccess: [],
      children: [],
    },

  };
  if (isFeatureEnabled) {
    pages = { ...pages, ...learning };
  }
  const pageNames = keys(pages);
  const handleClick = () => {
    setOpen(!open);
  };
  const isActive = (page) => {
    let activePage = [];
    activePage = page.children.filter((ele) => location.pathname.includes(ele.href));
    return !isEmpty(activePage);
  };
  const getButton = (page) => (
    <Button
      activeClassName={clsx({
        [classes.active]: !disableMenu,
      })}
      classes={{ root: classes.button, disabled: classes.buttonDisabled }}
      component={CustomRouterLink}
      disabled={disableMenu}
      to={page.href}
      color="secondary"
    >
      <ListItemIcon
        classes={{ root: classes.icon }}
        className={clsx({
          [classes.iconDisabled]: disableMenu,
        })}
      >
        {page.icon}
      </ListItemIcon>
      <span>
        <Typography variant="caption">
          {page.title}
        </Typography>
      </span>
    </Button>
  );
  const renderList = (page, className) => (
    <Can
      roleHasAccess={page.roleHasAccess}
      user={user}
      yes={() => (
        <ListItem
          className={clsx(classes.item, {
            [classes.nested]: className,
          })}
          key={page.title}
        >
          {getButton(page)}
        </ListItem>
      )}
    />
  );
  return (
    <>
      <List className={classes.root} disabled={disableMenu}>
        {pageNames.map((page) => (
          (isEmpty(pages[page].children) ? (
            renderList(pages[page])
          )
            : (
              <>
                <Can
                  roleHasAccess={pages[page].roleHasAccess}
                  user={user}
                  yes={() => (
                    <>
                      <ListItem
                        className={clsx(classes.item, {
                          [classes.activeParent]: (!disableMenu && isActive(pages[page])),
                          [classes.buttonDisabled]: !isActive(pages[page]),
                        })}
                        key={pages[page].title}
                        disabled={disableMenu}
                        onClick={handleClick}
                      >
                        <Button
                          disabled={disableMenu}
                        >
                          <ListItemIcon
                            classes={{ root: classes.icon }}
                            className={clsx({
                              [classes.iconDisabled]: disableMenu,
                            })}
                          >
                            {pages[page].icon}
                          </ListItemIcon>
                          <span>
                            <Typography variant="caption">
                              {pages[page].title}
                            </Typography>
                          </span>
                        </Button>
                        {open
                          ? (
                            <ExpandLess className={clsx({
                              [classes.iconDisabled]: disableMenu,
                            })}
                            />
                          )
                          : (
                            <ExpandMore className={clsx({
                              [classes.iconDisabled]: disableMenu,
                            })}
                            />
                          )}
                      </ListItem>
                      <Collapse in={open} timeout="auto">
                        <List>
                          {pages[page].children.map((child) => (
                            renderList(child, 'nested')
                          ))}
                        </List>
                      </Collapse>
                    </>
                  )}
                />
              </>
            )
          )
        ))}
      </List>
      <div style={{ flexGrow: 1 }} />
    </>
  );
};

SidebarNav.propTypes = {
  disableMenu: PropTypes.bool.isRequired,
};

export default SidebarNav;
