import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../ErrorBoundary';
import Loading from '../Loading';
import IdleTimer from '../IdleTimer';
import { NAMESPACE } from '../../constants/apiConstants';
import { ADMIN_ROLE, CLIENT_ID } from '../../constants/appConstants';

import ClientSelection from '../../containers/ClientSelection';

const RouteWithLayout = (props) => {
  const {
    layout: Layout, component: Component, appProps, path, disableMenu, hideTopBarClient, ...rest
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Route
      {...rest}
      path={path}
      render={(matchProps) => (
        <Layout disableMenu={disableMenu} hideTopBarClient={hideTopBarClient}>
          <ErrorBoundary key={path}>
            <Component {...matchProps} {...appProps} />
          </ErrorBoundary>
        </Layout>
      )}
    />
  );
};

RouteWithLayout.propTypes = {
  component: PropTypes.oneOfType(
    [PropTypes.func, PropTypes.element, PropTypes.node, PropTypes.instanceOf(Object)],
  ).isRequired,
  layout: PropTypes.func.isRequired,
  appProps: PropTypes.instanceOf(Object),
  path: PropTypes.string,
  disableMenu: PropTypes.bool,
  hideTopBarClient: PropTypes.bool.isRequired,
};

RouteWithLayout.defaultProps = {
  path: '',
  appProps: null,
  disableMenu: false,
};
const WithIdleTimer = (props) => {
  const { user } = useAuth0();
  const roles = user[`${NAMESPACE}roles`] || [];
  const isAdmin = roles.some((role) => role === ADMIN_ROLE);
  const clientId = isAdmin ? localStorage.getItem(CLIENT_ID) : null;
  let updatedProps = { ...props, hideTopBarClient: true };
  if (isAdmin && !clientId) {
    updatedProps = {
      ...updatedProps,
      component: ClientSelection,
      disableMenu: true,
    };
  } else if (isAdmin) {
    updatedProps = {
      ...updatedProps,
      hideTopBarClient: false,
    };
  }

  return (
    <>
      <IdleTimer />
      <RouteWithLayout {...updatedProps} />
    </>
  );
};

export const AuthenticateForAdmin = withAuthenticationRequired(WithIdleTimer, {
  onRedirecting: () => <Loading isLoading />,
});
/* checking for all reqd fields are filled or not.
   If not route to welcome page to fill all reqd fileds */
const validateUserData = (usr) => {
  const isProfileFulfilled = localStorage.getItem('IS_PROFILE_FULFILLED');
  const { family_name: lastName, given_name: firstName, nickname: displayName } = usr;
  const { language, consentGiven = '' } = usr[`${NAMESPACE}user_metadata`];
  const username = usr[`${NAMESPACE}username`];
  if ((!firstName
    || !lastName
    || !displayName
    || Object.keys(language).length === 0
    || !consentGiven
    || !username) && !isProfileFulfilled) return true;
  return false;
};

export const PrivateLayoutRoute = (props) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  /* User is always valid once user loggedin successfully,
     but we need some required metadata of user to be filled
     to access those data properly to run some part of our application. */
  if (isAuthenticated && validateUserData(user) && !isLoading) return <Redirect to="/welcome" />;
  if (isAuthenticated && !isLoading) return <WithIdleTimer {...props} />;
  if (!isAuthenticated && !isLoading) return <Redirect to="/login" />;
  return <Loading isLoading />;
};

export default RouteWithLayout;
