import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

import getAllClients from '../../../api/admin';
import { CLIENT_ID } from '../../../constants/appConstants';

const useStyles = makeStyles(() => ({
  clientButton: {
    marginRight: '10px',
  },
  popover: {
    minWidth: 200,
  },
}));

const SelectClient = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: { data: clientList = [] } = [] } = useQuery('GetAllClients', getAllClients);
  const [selectedIndex] = useState(JSON.parse(localStorage.getItem(CLIENT_ID)));
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetIndex = (option) => {
    localStorage.setItem(CLIENT_ID, JSON.stringify(option));
    handleClose();
    history.go(0);
  };

  return clientList && (
    <>
      <Button classes={{ root: classes.clientButton }} variant="outlined" fontSize="small" endIcon={<Icon>arrow_drop_down</Icon>} onClick={handleClick}>
        {selectedIndex?.name}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PopoverClasses={{
          paper: classes.popover,
        }}
      >
        {clientList.map((option) => (
          <MenuItem
            selected={selectedIndex.id === option.id}
            key={option.id}
            onClick={() => handleSetIndex(option)}
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default SelectClient;
