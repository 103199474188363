import { NAMESPACE } from '../../constants/apiConstants';

const check = (user, roleHasAccess) => {
  if (roleHasAccess.length === 0) return true;
  const roles = user[`${NAMESPACE}roles`] || [];
  return roles.some((role) => roleHasAccess.indexOf(role) !== -1);
};

const Can = (props) => (check(props.user, props.roleHasAccess)
  ? props.yes()
  : props.no());

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
