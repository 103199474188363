import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  hide: {
    display: 'none',
  },
  drawerOpen: {
    width: '150px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64,
    },
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
      paddingTop: 64,
    },
  },
  copy: {
    padding: '4px',
  },
  footer: {
    opacity: '0.4',
  },
  root: {
    paddingTop: '20px',
    paddingBottom: '11px',
  },
  iconButton: {
    background: theme.palette.disabledIcon,
    color: theme.palette.disabledIconColor,
    '&:hover': {
      background: theme.palette.disabledIcon,
    },
  },
  toggleIcon: {
    justifyContent: 'flex-end',
    padding: '5px',
  },
  toggleFooter: {
    justifyContent: 'center',
    padding: '5px',
  },
  drawerFooter: {
    display: 'block',
    top: '30%',
    cursor: 'pointer',
  },
  item: {
    padding: 0,
  },
  footerText: {
    textAlign: 'center',
    marginLeft: '3px',
    fontSize: '18px',
  },
  button: {
    width: '100%',
    color: theme.palette.text.primary,
    borderRadius: 0,
    opacity: '0.38',
    borderRight: '6px solid transparent',
    padding: '10px 0 10px 10px',
    justifyContent: 'flex-start',
  },
  buttonDisabled: {
    color: theme.palette.text.primary,
    opacity: '0.38',
  },
  icon: {
    marginRight: '10px',
    minWidth: '0',
    color: theme.palette.icon,
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
  },
  active: {
    borderRight: `6px solid ${theme.palette.secondary.main}`,
    background: theme.palette.background.container,
    opacity: '1',
  },
  nested: {
    '& a': {
      paddingLeft: '25px',
    },
  },
  activeParent: {
    background: theme.palette.background.container,
    opacity: '1',
  },
  iconDisabled: {
    opacity: '0.38',
  },
}));
export default useStyles;
